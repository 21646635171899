import React from "react";
import styled from "styled-components";

import resume from '../../static/resume.pdf';

const Footer = () => {
  return (
    <FooterWrapper>
      <p>
        Made with{" "}
        <span role="img" aria-label="heart">
          ❤️
        </span>{" "}
        by Maddie Beller
      </p>
      {/* <div>
        <a href="https://www.linkedin.com/in/madeleine-beller-1a22531b6/">
          LinkedIn
        </a>
        <p></p>
        <a href={resume}>Resume</a>
      </div> */}
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 40px;
  height: 150px;
  color: white;
  justify-content: space-around;
  align-items: center;
  background-color: ${(props) => props.theme.pastel};
`;
