import React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

export const Header = () => {
  return (
    <HeaderWrapper>
      <Link to="/" onClick={() => navigate("/")}>
        Home
      </Link>
      <nav>
        <Link to="/" activeClassName="active-nav">
          Home
        </Link>
        <Link to="/blog" activeClassName="active-nav">
          Blog
        </Link>
      </nav>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  height: ${(props) => props.theme.headerHeight};
  display: flex;
  justify-content: space-between;
  align-items: center;
  backdrop-filter: saturate(180%) blur(8px);
  background-color: rgba(255, 255, 255, 0.7);

  img {
    height: 85%;
    margin: 0;
    margin-left: 6px;
    :hover {
      cursor: pointer;
    }
  }

  a {
    margin: 0 20px;
    color: #2c3e50;
    text-decoration: none;
    :hover {
      cursor: pointer;
      color: ${({theme}) => theme.grass};
    }
  }

  .active-nav {
    font-weight: 600;
  }
`;
